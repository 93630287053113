import Head from 'next/head';

import PageWrapper from 'components/PageWrapper';
import privatePage from 'hocs/privatePage';
import EditorLayout from 'layouts/EditorLayout';
import GraphsView from 'views/Graphs';

function Home() {
  return (
    <>
      <Head>
        <title>Patterns | Home</title>
      </Head>
      <EditorLayout overflowY="auto">
        <PageWrapper>
          <GraphsView />
        </PageWrapper>
      </EditorLayout>
    </>
  );
}

export default privatePage(Home);
