import { useCallback, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import Router from 'next/router';
import shallow from 'zustand/shallow';

import useDuplicateGraph from 'hooks/api/useDuplicateGraph';
import useStore from 'state';
import { AppState } from 'types';
import { Graph, MarketplaceAppTemplate } from 'types/api';
import { makeGraphURL } from 'utils';

import useStorages from './api/useStorages';

const selector = (state: AppState) => ({
  organizationUID: state.organizationUID,
});

function useCloneGettingStartedTutorial() {
  const toast = useToast();
  const { organizationUID } = useStore(selector, shallow);

  const showToast = useCallback(
    (description: string, isSuccess: boolean) => {
      toast({
        description: description,
        status: isSuccess ? 'success' : 'error',
        isClosable: true,
        duration: isSuccess ? 3000 : undefined,
      });
    },
    [toast]
  );

  const {
    mutate: cloneGraph,
    error,
    isLoading,
  } = useDuplicateGraph({
    onMutate: () => {
      showToast('Creating app from template', true);
    },
    onSuccess: (graph: Graph) => {
      const graphUrl = makeGraphURL(graph.uid, graph.slug);
      Router.push({ pathname: graphUrl });
    },
    onError: () => {
      showToast('Error creating app from template', false);
    },
  });

  const { data: allStorages } = useStorages(organizationUID);

  const writableStorages = useMemo(() => {
    if (allStorages) {
      allStorages.results = allStorages.results
        .filter((storage) => !storage.read_only)
        .sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1));
    }
    return allStorages;
  }, [allStorages]);

  const databaseUID = useMemo(() => {
    if (writableStorages) {
      const defaultDB = writableStorages.results.find(
        (storage) => storage.name.toLowerCase() === 'default'
      );
      const managedDB = writableStorages.results.find((storage) => storage.managed);
      if (defaultDB) {
        return defaultDB.uid;
      } else if (managedDB) {
        return managedDB.uid;
      } else if (writableStorages.results.length > 0) {
        return writableStorages.results[0].uid;
      }
    }
    return null;
  }, [writableStorages]);

  const cloneGettingStartedTutorial = useCallback(
    (template: MarketplaceAppTemplate) => {
      if (databaseUID && organizationUID) {
        cloneGraph({
          graph_version_uid: template.version,
          organization_uid: organizationUID,
          title: template.title,
          storage_uid: databaseUID,
          compute: 'patterns-cloud',
        });
      }
    },
    [cloneGraph, organizationUID, databaseUID]
  );

  return { cloneGettingStartedTutorial, error, isLoading };
}

export default useCloneGettingStartedTutorial;
