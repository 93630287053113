import { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Box, Button, ChakraProps, Flex, HStack, useDisclosure, VStack } from '@chakra-ui/react';

import ModalComponent from 'components/Modal';
import { H6, P1 } from 'components/Typography';
import useMarketplaceStarterApps from 'hooks/useMarketplaceStarterApps';
import { GraphTemplateCategory, MarketplaceAppTemplate } from 'types/api';
import AppPreview from 'views/Marketplace/Apps/AppPreview';

import Templates from './Templates';

const sidebarTitleStyle: ChakraProps = {
  textTransform: 'uppercase',
  fontSize: '11px',
  lineHeight: '1.6',
  letterSpacing: '0.08em',
  color: '#000000',
};

const sidebarOptionsStyle: ChakraProps = {
  cursor: 'pointer',
  color: '#000000',
  fontSize: '16px',
  fontWeight: 600,
};

const sidebarFunctionOptionStyle: ChakraProps = {
  ...sidebarOptionsStyle,
  borderRadius: '3px',
  py: 0.5,
  px: 6,
  _hover: { bg: 'activeMenu' },
};

function categoryKey(category: GraphTemplateCategory): string {
  return category.title.toLowerCase().replace(' ', '_');
}

function CreateAppButton({ altDesign = false }: { altDesign?: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [previewTemplate, setPreviewTemplate] = useState<MarketplaceAppTemplate | null>(null);

  const [loadingTemplate, setLoadingTemplate] = useState(true);
  useEffect(() => {
    if (previewTemplate) {
      const t = setTimeout(() => {
        setLoadingTemplate(false);
      }, 3000);
      return () => clearTimeout(t);
    } else {
      setLoadingTemplate(true);
    }
  }, [setLoadingTemplate, previewTemplate]);

  const handleClose = () => {
    onClose();
    setPreviewTemplate(null);
  };

  const { data: categories, isLoading } = useMarketplaceStarterApps();

  return (
    <Flex flex={1} justifyContent="flex-end">
      <Button
        onClick={onOpen}
        size={altDesign ? 'md' : 'sm'}
        data-testid="create-app-button"
        leftIcon={<FiPlus />}
        variant={altDesign ? 'outline' : 'solid'}
        p={5}
      >
        Create App
      </Button>

      {isOpen && (
        <ModalComponent
          size="5xl"
          showFooter={false}
          title={!previewTemplate ? 'Start with a Template' : undefined}
          onClose={handleClose}
          withDivider
          modalBodyProps={{
            p: 0,
            mb: previewTemplate ? 10 : 0,
          }}
        >
          {previewTemplate ? (
            <AppPreview
              uid={previewTemplate.uid}
              setItem={() => {}}
              showHeader={false}
              hideRelatedItems={true}
              onBack={handleClose}
              createAppFlow={true}
            />
          ) : (
            <HStack align="flex-start">
              <VStack spacing={4} align="flex-start" pt={4} px={4} height="100%" flex={1}>
                <Box w="max-content" position="sticky" top="0">
                  <H6 {...sidebarTitleStyle}>Function</H6>

                  <Box mt={2.5} experimental_spaceY={2}>
                    {categories.map((category) => (
                      <P1
                        key={categoryKey(category)}
                        {...sidebarFunctionOptionStyle}
                        onClick={() => {
                          document
                            .getElementById(categoryKey(category))
                            ?.scrollIntoView({ behavior: 'smooth' });
                        }}
                      >
                        {category.title === 'Featured and Starter' ? 'Featured' : category.title}
                      </P1>
                    ))}
                  </Box>
                </Box>
              </VStack>

              <VStack
                pt={8}
                spacing={8}
                px={14}
                pb={20}
                align="flex-start"
                w="full"
                borderLeft="1px"
                borderLeftColor="border3"
              >
                {categories.map((category) => (
                  <Templates
                    id={categoryKey(category)}
                    key={categoryKey(category)}
                    category={category}
                    setPreviewTemplate={setPreviewTemplate}
                    onClick={handleClose}
                  />
                ))}
              </VStack>
            </HStack>
          )}
        </ModalComponent>
      )}
    </Flex>
  );
}

export default CreateAppButton;
