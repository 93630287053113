import create from 'zustand';

type State = {
  isExecuting: boolean;
  isTrying: boolean;
  showDiff: boolean;
};

type Actions = {
  setIsExecuting: (isExecuting: boolean) => void;
  setIsTrying: (isTrying: boolean) => void;
  setShowDiff: (showDiff: boolean) => void;
  reset: () => void;
};

export type EditorState = State & Actions;

const initialState: State = {
  isExecuting: false,
  isTrying: false,
  showDiff: false,
};

const useStore = create<EditorState>((set, get) => ({
  ...initialState,

  setIsExecuting: (isExecuting: boolean) => {
    set({ isExecuting });
  },

  setIsTrying: (isTrying: boolean) => {
    set({ isTrying });
  },

  setShowDiff: (showDiff: boolean) => {
    set({ showDiff });
  },

  reset: () => {
    set(initialState);
  },
}));

export default useStore;
