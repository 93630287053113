import React from 'react';
import { Center, Spinner, StackDivider, useColorMode, VStack } from '@chakra-ui/react';

import GraphList from 'components/GraphList';
import useGraphs from 'hooks/api/useGraphs';

function Graphs() {
  const { isLoading } = useGraphs();

  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';

  return (
    <>
      {isLoading ? (
        <Center h="100%" width="100%">
          <Spinner />
        </Center>
      ) : (
        <VStack
          spacing={8}
          mb={8}
          divider={<StackDivider borderColor={isDarkMode ? 'border1' : 'border2'} mb={14} />}
        >
          <GraphList />
        </VStack>
      )}
    </>
  );
}

export default Graphs;
