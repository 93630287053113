import { useMemo, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  Box,
  Button,
  Center,
  ChakraProps,
  Divider,
  Flex,
  Grid,
  Link as ChakraLink,
  Spinner,
} from '@chakra-ui/react';
import Link from 'next/link';
import Router from 'next/router';
import { colors } from 'styles/colors';

import CreateAppButton from 'components/CreateAppButton/CreateAppButton';
import GraphCard from 'components/GraphCard/GraphCard';
import ModalComponent from 'components/Modal';
import PageTitle from 'components/PageTitle';
import { H2, H3, P2 } from 'components/Typography';
import useGraphs from 'hooks/api/useGraphs';
import useMarketplaceFeaturedApps from 'hooks/useMarketplaceFeaturedApps';
import { Graph, MarketplaceAppTemplate } from 'types/api';
import { makeGraphURL } from 'utils';
import { syntaxDark } from 'utils/darktheme';
import AppPreview from 'views/Marketplace/Apps/AppPreview';
import AppsList from 'views/Marketplace/Apps/AppsList';

type GraphListProps = ChakraProps & {};

const GraphHeading = () => (
  <PageTitle
    title="Apps"
    description="Apps are the main object of the Patterns platform. With apps you can ingest and
integrate data, build and deploy pipelines, models, and interfaces."
  />
);

function GraphList({}: GraphListProps) {
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useGraphs();

  const graphs = useMemo(() => {
    return data?.pages ? data?.pages.map((page) => page.results).flat() : [];
  }, [data?.pages]);

  const [previewTemplate, setPreviewTemplate] = useState<MarketplaceAppTemplate | null>(null);

  const { data: marketplaceFeaturedApps, isLoading: isFeaturedAppsLoading } =
    useMarketplaceFeaturedApps();

  const renderApps = () => {
    if (isLoading) {
      return (
        <Center my={6}>
          <Spinner />
        </Center>
      );
    }

    if (!graphs || graphs.length === 0) {
      return (
        <>
          <Flex flex={1} flexDir="column" gap={25}>
            <Flex justify="space-between">
              <H2>Welcome to Patterns!</H2>
              <CreateAppButton />
            </Flex>
            <Flex direction="column" gap={1}>
              <H3>Getting Started</H3>
              <P2>Try cloning one of these sample apps</P2>
            </Flex>
            <Flex>
              <AppsList
                apps={marketplaceFeaturedApps}
                onClick={(uid) => {
                  const t = marketplaceFeaturedApps.find(
                    (template: MarketplaceAppTemplate) => template.uid === uid
                  );

                  if (t) {
                    setPreviewTemplate(t);
                  }
                }}
              />
            </Flex>
            <Divider />
            <Flex direction="column" gap={2}>
              <Flex direction="column" gap={1}>
                <H3>Prefer your own code editor?</H3>
                <P2>
                  Create a new app with our{' '}
                  <Link href="https://www.patterns.app/docs/devkit/">Local Development Kit</Link>
                </P2>
              </Flex>
              <Flex bg={colors.dark.bg1} overflow="hidden" padding={5} borderRadius={5}>
                <SyntaxHighlighter
                  style={{ ...syntaxDark }}
                  customStyle={{
                    fontSize: '14px',
                    width: '100%',
                    fontWeight: 600,
                    lineHeight: 1.6,
                  }}
                  language="bash"
                >
                  {`pip install patterns-devkit
patterns login
patterns create app
cd my_app
patterns create node
patterns upload
`}
                </SyntaxHighlighter>
              </Flex>
            </Flex>
            <Divider />
            <Flex direction="column" gap={2}>
              <Flex direction="column" gap={1}>
                <H3>Want to look around?</H3>
                <P2>Explore our marketplace for other apps to clone</P2>
              </Flex>
              <Flex>
                <Button
                  size="sm"
                  p={5}
                  variant="outline"
                  onClick={() => Router.push({ pathname: 'marketplace' })}
                >
                  Marketplace
                </Button>
              </Flex>
            </Flex>
          </Flex>
          {previewTemplate && (
            <ModalComponent
              minWidth="1400px"
              showFooter={false}
              title={undefined}
              onClose={() => setPreviewTemplate(null)}
              withDivider
              modalBodyProps={{ p: 0, mb: 10 }}
            >
              <AppPreview
                uid={previewTemplate.uid}
                setItem={() => {}}
                showHeader={false}
                hideRelatedItems={true}
                createAppFlow={true}
              />
            </ModalComponent>
          )}
        </>
      );
    }

    return (
      <Flex flex={1} flexDir="column" gap={5}>
        <Flex justify="space-between" gap={5} flexDir={{ base: 'column', md: 'row' }}>
          <GraphHeading />
          <CreateAppButton />
        </Flex>

        <Grid
          templateColumns={{
            base: 'repeat(1, minmax(0, 1fr))',
            lg: 'repeat(2, minmax(0, 1fr))',
            xl: 'repeat(3, minmax(0, 1fr))',
          }}
          columnGap={8}
          rowGap={8}
        >
          {(graphs as Graph[]).map(
            ({ uid, title, updated_at: updatedAt, slug, manifest, theme, enabled }) => {
              return (
                <GraphCard
                  key={uid}
                  uid={uid}
                  title={title || slug || ''}
                  slug={slug || ''}
                  theme={theme || 'default'}
                  onClick={() => {
                    const graphURL = makeGraphURL(uid, slug);
                    Router.push(graphURL);
                  }}
                  updatedAt={updatedAt}
                  manifest={manifest}
                  enabled={enabled}
                />
              );
            }
          )}
        </Grid>
      </Flex>
    );
  };

  return (
    <Box w="full">
      {renderApps()}

      <Center>
        {hasNextPage && (
          <Center my={5}>
            {isFetchingNextPage ? (
              <Box pr={5}>
                <Spinner size="xs" />
              </Box>
            ) : (
              <ChakraLink
                display="flex"
                alignItems="center"
                color="light.action"
                fontWeight={500}
                onClick={() => {
                  hasNextPage && fetchNextPage();
                }}
              >
                See more
              </ChakraLink>
            )}
          </Center>
        )}
      </Center>
    </Box>
  );
}

export default GraphList;
