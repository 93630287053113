import { ComponentType, FC, ReactElement, useEffect, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';

import Loader from 'components/Loader';
import { useAuth } from 'contexts/AuthContext';
import { FB_PIXEL_INIT } from 'utils/fpixel';

export type PrivatePageComponentProps = {
  isAuthenticating: boolean;
};

const privateRoute = <P extends object>(Component: ComponentType<P>) => {
  return function PrivateComponent(props: any): FC<P> | ReactElement {
    const router = useRouter();
    const { isLoading, isAuthenticated, account, accountError } = useAuth();

    const isGraphPage = router.pathname.includes('/graph');
    const isMarketplace = router.pathname.includes('/marketplace');

    const isAuthenticating = useMemo(() => {
      return (
        isLoading ||
        !!(
          isAuthenticated &&
          router.pathname === '/home' &&
          router.query.code &&
          router.query.state
        )
      );
    }, [isLoading, isAuthenticated, router.pathname, router.query.code, router.query.state]);

    const isAuthenticatedCustom = useMemo(() => {
      // graph page is edge case, where you don't have to be authenticated
      // but if you are, then we enforce isVerified and isSetup
      return (isAuthenticated || isGraphPage || isMarketplace) && !isAuthenticating;
    }, [isAuthenticated, isGraphPage, isMarketplace, isAuthenticating]);

    const isUnverified = useMemo(() => {
      return (
        isAuthenticated &&
        accountError?.response?.data.detail === 'unverified email' &&
        !router.pathname.includes('/verify-email')
      );
    }, [accountError?.response?.data.detail, isAuthenticated, router.pathname]);

    const isUnsetup = useMemo(() => {
      return (
        isAuthenticated &&
        accountError?.response?.data.detail === 'incomplete setup' &&
        !router.pathname.includes('/account-setup')
      );
    }, [accountError?.response?.data.detail, isAuthenticated, router.pathname]);

    useEffect(() => {
      if (!isAuthenticating) {
        if (!isAuthenticatedCustom) {
          router.replace({
            pathname: '/auth/login',
            query: { returnTo: router.query.returnTo || router.asPath },
          });
        } else if (isUnverified) {
          router.replace({
            pathname: '/verify-email',
            query: { returnTo: router.query.returnTo || router.asPath },
          });
        } else if (isUnsetup) {
          router.replace({
            pathname: '/account-setup',
            query: { returnTo: router.query.returnTo || router.asPath },
          });
        }
      }
    }, [isAuthenticating, isAuthenticatedCustom, isUnverified, isUnsetup, router]);

    useEffect(() => {
      if (process.env.NEXT_PUBLIC_ENABLE_POSTHOG !== 'false' && posthog && account?.uid) {
        posthog.identify(account.uid, {
          email: account.email,
          name: `${account.first_name} ${account.last_name}`,
        });
      }

      if (account?.uid) {
        FB_PIXEL_INIT(account);
      }

      if (process.env.NEXT_PUBLIC_ENABLE_APPCUES === 'true' && account?.uid && window.Appcues) {
        window.Appcues.identify(account.uid),
          {
            accountId: account.uid,
            firstName: account.first_name,
            lastName: account.last_name,
            email: account.email,
          };
      }

      if (process.env.NEXT_PUBLIC_ENABLE_CHAMELEON === 'true' && account?.uid && window.chmln) {
        window.chmln?.identify(account.uid, {
          name: [account.first_name, account.last_name].join(' '),
          email: account.email,
        });
      }
    }, [account]);

    const showComponent = !isLoading && isAuthenticatedCustom && !isUnsetup && !isUnverified;

    // if showComponent is true and showLoader is true
    if (!showComponent) {
      return (
        <Box pos={'absolute'} left="0" top="0" width="100%" height="100%" backgroundColor="white">
          <Loader />
        </Box>
      );
    }

    // render the component but hide it until showLoader=false, to prevent flashes and give it a chance to load
    return (
      <Box height="100%" width="100%">
        <Component {...props} />
      </Box>
    );
  };
};

export default privateRoute;
