import { useMemo } from 'react';

import useMarketplaceAppsByCategory from './api/useMarketplaceAppsByCategory';

function useMarketplaceStarterApps() {
  const { data: appsByCategory, isLoading } = useMarketplaceAppsByCategory();

  const starterApps = useMemo(() => {
    if (appsByCategory) {
      return appsByCategory.categories.filter((category) => category.is_starter);
    } else {
      return [];
    }
  }, [appsByCategory]);

  return { isLoading, data: starterApps };
}

export default useMarketplaceStarterApps;
