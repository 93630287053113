import { Box, Grid } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import GraphCard from 'components/GraphCard';
import { H2 } from 'components/Typography';
import useCloneGettingStartedTutorial from 'hooks/useCloneGettingStartedTutorial';
import useStore from 'state';
import { AppState } from 'types';
import { GraphTemplateCategory, MarketplaceAppTemplate } from 'types/api';

const selector = (state: AppState) => ({
  organizationUID: state.organizationUID,
  setShowCreateAppModal: state.setShowCreateAppModal,
});
type TemplatesProps = {
  id: string;
  category: GraphTemplateCategory;
  setPreviewTemplate: (template: MarketplaceAppTemplate) => void;
  onClick?: Function;
};

function Templates({ id, category, setPreviewTemplate, onClick }: TemplatesProps) {
  const { organizationUID, setShowCreateAppModal } = useStore(selector, shallow);
  const { cloneGettingStartedTutorial } = useCloneGettingStartedTutorial();

  return (
    <Box id={id} w="full">
      <H2 mb={4}>{category.title}</H2>

      <Grid
        templateColumns={{
          base: 'repeat(1, minmax(0, 1fr))',
          lg: 'repeat(2, minmax(0, 1fr))',
        }}
        columnGap={4}
        rowGap={4}
      >
        {category.templates.map((template) => {
          const showNodes = category.title != 'Starter';
          const handlePreview = () => {
            if (template.title.toLowerCase().includes('blank')) {
              setShowCreateAppModal({
                orgUID: organizationUID!,
                graphUID: template.graph_uid,
                graphVersionUID: null,
                title: template.title,
                description: template.description,
                theme: 'default',
              });
              !!onClick && onClick();
            } else if (template.title.toLowerCase().includes('getting started tutorial')) {
              cloneGettingStartedTutorial(template);
              !!onClick && onClick();
            } else {
              setPreviewTemplate(template);
            }
          };

          return (
            <GraphCard
              key={template.graph_uid}
              uid={template.graph_uid}
              title={template.title || template.slug || ''}
              slug={template.slug || ''}
              readOnly={true}
              manifest={showNodes ? template.manifest : undefined}
              onClick={handlePreview}
              description={template.short_description || ''}
            />
          );
        })}
      </Grid>
    </Box>
  );
}

export default Templates;
